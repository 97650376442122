@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Clash Display";
  src: url("/src/assets/fonts/ClashDisplay-Extralight.otf") format("opentype");
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: "Clash Display";
  src: url("/src/assets/fonts/ClashDisplay-Light.otf") format("opentype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Clash Display";
  src: url("/src/assets/fonts/ClashDisplay-Regular.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Clash Display";
  src: url("/src/assets/fonts/ClashDisplay-Semibold.otf") format("opentype");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Clash Display";
  src: url("/src/assets/fonts/ClashDisplay-Medium.otf") format("opentype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Clash Display";
  src: url("/src/assets/fonts/ClashDisplay-Bold.otf") format("opentype");
  font-weight: 700;
  font-style: normal;
}

body {
  font-family: "Clash Display", sans-serif;
}
/* body {
  font-family: "Libre Franklin", sans-serif;
} */

::-webkit-scrollbar {
  width: 0;
  display: none;
}
::-webkit-scrollbar-track {
  background-color: rgb(255, 255, 255);
}
::-webkit-scrollbar-thumb {
  background-color: rgb(39, 37, 86);

  /* border-radius: 50px; */
}

@media (max-width: 840px) {
  ::-webkit-scrollbar {
    display: none;
  }
}
.toastBody {
  color: #10171d; /* #10171D */
  font-size: 1.3rem !important;
}

@keyframes grow {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.animate-image {
  animation: grow 3s infinite;
}

.progress-bar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 10px;
  background: #022c22;
  transform-origin: 0%;
}

.main-bg {
  background: linear-gradient(
      180deg,
      #000000a3 10%,
      rgba(0, 0, 0, 0.61) 103.56%
    ),
    url("./assets/image/hearing.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: scroll;
}
.about-bg {
  background: linear-gradient(
      180deg,
      #00000081 10%,
      rgba(0, 0, 0, 0.799) 103.56%
    ),
    url("./assets/hero/about-us.jpeg");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: scroll;
}
.main-2-bg {
  background: linear-gradient(180deg, #000000a3 10%, rgba(0, 0, 0, 0) 103.56%),
    url("./assets/image/Become_a_Lawyer.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: scroll;
}
.main-3-bg {
  background: linear-gradient(180deg, #000000a3 10%, rgba(0, 0, 0, 0) 103.56%),
    url("./assets/image/hearing.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: scroll;
}
.main-4-bg {
  background: linear-gradient(180deg, #000000a3 10%, rgba(0, 0, 0, 0) 103.56%),
    url("./assets/image/Become_a_Lawyer.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: scroll;
}

.circles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.circles li {
  position: absolute;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  background: #bbf7d0;
  animation: animate 25s linear infinite;
  bottom: -150px;
}

.circles li:nth-child(1) {
  left: 25%;
  width: 80px;
  height: 80px;
  animation-delay: 0s;
}

.circles li:nth-child(2) {
  left: 10%;
  width: 20px;
  height: 20px;
  animation-delay: 2s;
  animation-duration: 12s;
}

.circles li:nth-child(3) {
  left: 70%;
  width: 20px;
  height: 20px;
  animation-delay: 4s;
}

.circles li:nth-child(4) {
  left: 40%;
  width: 60px;
  height: 60px;
  animation-delay: 0s;
  animation-duration: 18s;
}

.circles li:nth-child(5) {
  left: 65%;
  width: 20px;
  height: 20px;
  animation-delay: 0s;
}

.circles li:nth-child(6) {
  left: 75%;
  width: 110px;
  height: 110px;
  animation-delay: 3s;
}

.circles li:nth-child(7) {
  left: 35%;
  width: 150px;
  height: 150px;
  animation-delay: 7s;
}

.circles li:nth-child(8) {
  left: 50%;
  width: 25px;
  height: 25px;
  animation-delay: 15s;
  animation-duration: 45s;
}

.circles li:nth-child(9) {
  left: 20%;
  width: 15px;
  height: 15px;
  animation-delay: 2s;
  animation-duration: 35s;
}

.circles li:nth-child(10) {
  left: 85%;
  width: 150px;
  height: 150px;
  animation-delay: 0s;
  animation-duration: 11s;
}

@keyframes animate {
  0% {
    transform: translateY(0) rotate(0deg);
    opacity: 1;
    border-radius: 0;
  }

  100% {
    transform: translateY(-1000px) rotate(720deg);
    opacity: 0;
    border-radius: 50%;
  }
}

.hero-pattern {
  background-image: url("data:image/svg+xml,%3Csvg width='100' height='100' viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11 18c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm48 25c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm-43-7c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm63 31c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM34 90c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm56-76c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM12 86c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm28-65c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm23-11c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-6 60c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm29 22c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zM32 63c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm57-13c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-9-21c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM60 91c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM35 41c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM12 60c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2z' fill='%239C92AC' fill-opacity='0.4' fill-rule='evenodd'/%3E%3C/svg%3E");
}

.first-bg {
  background: linear-gradient(180deg, #00000000 10%, rgba(0, 0, 0, 0) 103.56%),
    url("./assets/hero/9.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-attachment: scroll;
}

.second-bg {
  background: linear-gradient(180deg, #00000000 10%, rgba(0, 0, 0, 0) 103.56%),
    url("./assets/hero/2.jpeg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-attachment: scroll;
}
.third-bg {
  background: linear-gradient(180deg, #00000000 10%, rgba(0, 0, 0, 0) 103.56%),
    url("./assets/hero/3.jpeg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-attachment: scroll;
}
.fourth-bg {
  background: linear-gradient(180deg, #00000000 10%, rgba(0, 0, 0, 0) 103.56%),
    url("./assets/hero/4.jpeg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-attachment: scroll;
}
.fifth-bg {
  background: linear-gradient(180deg, #00000000 10%, rgba(0, 0, 0, 0) 103.56%),
    url("./assets/hero/5.jpeg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-attachment: scroll;
}
.sixth-bg {
  background: linear-gradient(180deg, #00000000 10%, rgba(0, 0, 0, 0) 103.56%),
    url("./assets/hero/6.jpeg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-attachment: scroll;
}
.seventh-bg {
  background: linear-gradient(180deg, #00000000 10%, rgba(0, 0, 0, 0) 103.56%),
    url("./assets/hero/1.jpeg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-attachment: scroll;
}
.eight-bg {
  background: linear-gradient(180deg, #00000000 10%, rgba(0, 0, 0, 0) 103.56%),
    url("./assets/hero/8.jpeg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-attachment: scroll;
}
